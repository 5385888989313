.projects-container {
  background-color: #212121;
  color: #fff;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
}

.loading,
.error {
  color: #f44336;
  text-align: center;
  margin: 20px 0;
}

.empty {
  color: #888;
  text-align: center;
  margin: 20px 0;
  font-size: 1.2rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.project-card {
  background-color: #333;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
}

.project-title {
  font-size: 1.5rem;
  margin: 0 0 10px;
}

.project-description {
  font-size: 1rem;
  margin: 0 0 10px;
  color: #ddd;
}

.language-stats {
  margin-top: 10px;

  .language-bar {
    margin-bottom: 5px;
    font-size: 0.875rem;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;

    .language-fill {
      height: 8px;
      border-radius: 4px;
      transition: width 0.3s ease-in-out;
    }
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;

  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    // border: none;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 12px;
    font-size: 9px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    outline: none;
    text-decoration: none;
    letter-spacing: 1px;

    &:hover {
      background: linear-gradient(135deg, #1e88e5, #0d47a1);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    }

    &.live-button {
      background: linear-gradient(135deg, #4caf50, #388e3c);
      color: white;

      &:hover {
        background: linear-gradient(135deg, #66bb6a, #2e7d32);
      }
    }

    &.source-button {
      color: white;
      background: linear-gradient(135deg, #ff9800, #f57c00);

      &:hover {
        background: linear-gradient(135deg, #ffa726, #ef6c00);
      }
    }

    .button-icon {
      margin-right: 10px;
      font-size: 1.2rem;
    }
  }
}
