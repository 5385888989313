$linkedin-blue: #f44336;
$dark-text: white;
$light-bg: transparent;
$border-color: #ddd;
$font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$background-color: #111;
$text-color: #fff;
$heading-color: #cc0000;
$input-bg-color: #222;
$input-border-color: #444;
$input-focus-color: $heading-color;
$label-color: #ccc;
$border-radius: 4px;

.form-container {
  margin: 2rem auto;
  padding: 1rem;
  background: transparent;
  font-family: $font-family;
  box-shadow: $box-shadow;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  h2 {
    color: $linkedin-blue;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid $linkedin-blue;
    padding-bottom: 0.5rem;
  }
  p {
    margin-top: 1rem;
    color: $dark-text;
  }
  label {
    display: inline-flex;
    align-items: center;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-right: 1rem;
    font-weight: bold;
  }
  input {
    flex: 1;
    height: 2.5rem;
    line-height: 2.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 0.5rem;
  }
  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="checkbox"],
  textarea,
  select {
    background-color: $input-bg-color;
    color: $text-color;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    font-size: 1rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    &:focus {
      outline: none;
      border-color: $input-focus-color;
      box-shadow: 0 0 4px rgba($input-focus-color, 0.5);
    }
  }
  input[type="checkbox"] {
    width: auto;
    margin-right: 0.5rem;
    transform: scale(1.2);
    flex: none;
  }
  button {
    background-color: $heading-color;
    color: $text-color;
    border: none;
    border-radius: $border-radius;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.2s ease;
    &:hover {
      background-color: darken($heading-color, 10%);
    }
  }
  > .basic-info,
  > .social-accounts,
  > .experience,
  > .education,
  > .skills {
    margin-bottom: 2rem;
    width: 32%;
    min-width: 300px;
    height: max-content;
    div {
      display: flex;
      gap: 10px;
    }
  }
  .basic-info,
  .social-accounts {
    div {
      display: flex;
      label {
        font-weight: bold;
        margin-bottom: 0.25rem;
        color: $dark-text;
      }
      input[type="text"],
      input[type="email"],
      textarea {
        padding: 0.5rem;
        font-size: 1rem;
        &:focus {
          outline: none;
          border-color: $linkedin-blue;
          box-shadow: 0 0 4px rgba($linkedin-blue, 0.3);
        }
      }
      input,
      textarea {
        width: 100%;
        // max-width: 300px;
      }
      textarea {
        height: 100px;
      }
      input[type="checkbox"] {
        margin-right: 0.5rem;
        transform: scale(1.2);
      }
    }
  }
  .experience,
  .education {
    > div {
      background: $light-bg;
      margin-bottom: 1rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
      p {
        margin: 0.25rem 0;
        font-size: 0.95rem;
      }
      button {
        align-self: flex-end;
        background: $linkedin-blue;
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
        transition: background 0.2s ease;
        &:hover {
          background: darken($linkedin-blue, 10%);
        }
      }
    }
    > div:last-of-type {
      @media (max-width: 600px) {
        grid-template-columns: 1fr;
      }
      label {
        font-weight: bold;
        margin-bottom: 0.25rem;
        color: $dark-text;
      }
      textarea {
        width: 100%;
        height: 100px;
        font-size: 1rem;
        &:focus {
          outline: none;
          border-color: $linkedin-blue;
          box-shadow: 0 0 4px rgba($linkedin-blue, 0.3);
        }
      }
      input[type="text"],
      input[type="date"],
      textarea {
        padding: 0.5rem;
        font-size: 1rem;
        &:focus {
          outline: none;
          border-color: $linkedin-blue;
          box-shadow: 0 0 4px rgba($linkedin-blue, 0.3);
        }
      }
    }
  }
  .skills {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $light-bg;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        border: 1px solid $border-color;
        border-radius: 4px;
        font-size: 1rem;
        button {
          background: $linkedin-blue;
          color: #fff;
          border: none;
          padding: 0.3rem 0.6rem;
          border-radius: 4px;
          font-size: 0.9rem;
          cursor: pointer;
          transition: background 0.2s ease;
          &:hover {
            background: darken($linkedin-blue, 10%);
          }
        }
      }
    }
    input[type="text"] {
      width: calc(100% - 120px);
      margin-right: 1rem;
      padding: 0.5rem;
      border: 1px solid $border-color;
      border-radius: 4px;
      font-size: 1rem;
      &:focus {
        outline: none;
        border-color: $linkedin-blue;
        box-shadow: 0 0 4px rgba($linkedin-blue, 0.3);
      }
    }
    button {
      background: $linkedin-blue;
      color: #fff;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      cursor: pointer;
      transition: background 0.2s ease;
      &:hover {
        background: darken($linkedin-blue, 10%);
      }
    }
  }
  button[type="submit"] {
    display: block;
    width: 100%;
    background: $linkedin-blue;
    color: #fff;
    border: none;
    padding: 1rem;
    border-radius: 4px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover {
      background: darken($linkedin-blue, 10%);
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #222 !important;
  -webkit-text-fill-color: #fff !important;
  box-shadow: 0 0 0 1000px #222 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
