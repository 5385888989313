$mainColor: #212121;
$hoverColor: rebeccapurple;
$textColor: white;
$inputBorderColor: gainsboro;
.chat-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.chat-wrapper {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px 20px;
  background-color: #212121;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 0 10px $mainColor; /* 10px matches padding size 
  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: $hoverColor;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}

.message-input {
  background-color: $mainColor;
  color: $textColor;
  transition: border-color 0.2s;
  width: 100%;

  &:focus,
  &:active {
    border-color: $hoverColor;
    outline: none; // Remove default outline
  }
}

.MuiOutlinedInput-notchedOutline {
  border: solid 1px white !important;
}

.microphone-icon {
  position: absolute;
  left: 10px;
  color: $inputBorderColor;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.2s ease;

  &.listening {
    color: $hoverColor;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $mainColor;

  .microphone-icon,
  .tts-button {
    cursor: pointer;
    font-size: 1.2rem;
    color: $inputBorderColor;
    margin-right: 8px;
    transition: color 0.2s ease;

    &:hover {
      color: $hoverColor;
    }
  }
}

.send-button {
  button,
  svg {
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 10px;
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    color: red;
    &:hover {
      background-color: lighten($mainColor, 10%);
    }
  }
}
.command-suggestions {
  position: absolute;
  bottom: 50px;
  left: 10px;
  background-color: $mainColor;
  border-radius: 8px;
  padding: 5px 10px;
  box-shadow: 0 0 5px $hoverColor;

  .suggestion-item {
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
      background-color: $hoverColor;
      color: $textColor;
    }
  }
  .active {
    background-color: $hoverColor;
    color: $textColor;
  }
}

.hover {
  button,
  svg {
    color: red;
    background-color: lighten($mainColor, 10%);
  }
}

.display-chat {
  height: 90%;
  overflow-y: scroll;
  margin-bottom: 10px;
  padding: 0 20px 20px 20px;
}
.message-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #333;
  width: 100%; /* Ensure the item takes up full width */

  /* Set message span to fill the available space */
  .message {
    display: block;
    font-size: 16px;
    color: $textColor;
    flex-grow: 1; /* Expand to fill container height */
    width: 100%; /* Ensure full width */
    white-space: pre-wrap; /* Preserve line breaks and spacing */
    word-break: break-word; /* Wrap long words */
  }

  .timestamp {
    font-style: italic;
    font-size: 0.7rem;
    color: $inputBorderColor;
    text-align: right;
    margin-top: 8px; /* Space between message and timestamp */
  }
}

.copy-button {
  background-color: #bbbbbb87;
  border: none;
  color: $textColor;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $hoverColor;
  }
}

/* Mute Toggle Button Styling */
.mute-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  margin-left: 10px; /* Add space between the mute toggle and other elements */
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $mainColor;
  justify-content: space-between;

  .input-container {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-grow: 1;
  }
  span {
    color: red;
  }
  .microphone-icon,
  .mute-toggle {
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: $hoverColor;
    }
  }
}
.chat-sidebar {
  width: 250px;
  background-color: #212121;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 0px;
}
.sidebar-title {
  padding: 20px;
  border-bottom: 1px solid #444;
  text-align: center;
}
.channel-list {
  flex-grow: 1;
  overflow-y: auto;
}

.channel-list .MuiListItem-root {
  padding: 10px 20px;
  cursor: pointer;
}

.channel-list .MuiListItem-root:hover {
  background-color: #333;
}

.selected-channel {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

.mute-section {
  padding: 20px;
  border-top: 1px solid #444;
  text-align: center;
}

.mute-section .MuiTypography-root {
  margin-bottom: 10px;
}
.settings-section {
  padding: 20px;
  border-top: 1px solid #444;
  text-align: center;
}
