@import "../index.scss";

.password-manager-container {
  min-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  button {
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border: solid 1px gray;
    padding: 10px;
    transition: all ease 0.3s;
  }

  button:hover {
    background-color: rebeccapurple;
  }

  .buttons {
    display: flex;
    gap: 10px;
  }
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto 0px;
  gap: 10px;
  padding: 10px;
  select {
    padding: 10px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    border: 1px solid #ccc;
    cursor: pointer;
    min-width: 200px;
    border-radius: 5px;
    outline: none;

    option {
      background-color: $mainColor;
    }
  }
}

.passwords-table {
  width: 100%;

  th,
  td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: transparent;
  }
}

.actions {
  display: flex;
  align-items: center;

  button {
    padding: 8px 16px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 14px;
  }

  button:hover {
    background-color: #0056b3;
  }
}

/* Assuming your modal container has a class .modal */
.modal {
  background-color: rgba(0, 0, 0, 0.8); /* Dark transparent background */
  color: white; /* White text for contrast */
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modal .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .password-manager-container {
    min-width: 100%;
    padding: 10px;
  }

  .container {
    flex-direction: column;
    gap: 20px;
  }

  .passwords-table th,
  .passwords-table td {
    font-size: 14px;
    padding: 8px;
  }

  .actions button {
    padding: 6px 12px;
    font-size: 12px;
    margin-right: 5px;
  }
}

@media (max-width: 480px) {
  .password-manager-container {
    padding: 5px;
  }

  .container select {
    font-size: 14px;
    min-width: 100%;
  }

  .buttons {
    flex-direction: column;
    gap: 5px;
  }

  .passwords-table th,
  .passwords-table td {
    font-size: 12px;
  }

  .actions button {
    padding: 4px 8px;
    font-size: 10px;
  }

  .modal {
    padding: 15px;
    width: 90%;
  }
}
.table-container {
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 1px solid #444;
  border-radius: 8px;
  overflow: hidden;
}

.passwords-table {
  width: 100%;
  border-collapse: collapse;
  color: #fff;
  font-size: 16px;
}

.passwords-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.passwords-table th,
.passwords-table td {
  padding: 12px;
  border-bottom: 1px solid #444;
  text-align: left;
}

.passwords-table th {
  font-weight: bold;
}

.table-container tbody {
  display: block;
  height: 400px;
  overflow-y: auto;
}

.passwords-table thead,
.passwords-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.masked-password {
  font-size: 1.2em;
  letter-spacing: 2px;
}

.icon-button {
  background: none;
  border: none;
  color: #bbb;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s ease-in-out;
}

.icon-button:hover {
  color: #fff;
}

.delete-button {
  color: #e74c3c;
}

.delete-button:hover {
  color: #ff6b6b;
}

.copied-text {
  font-size: 0.8em;
  color: #4caf50;
  margin-left: 5px;
  font-weight: bold;
}

.buttons {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .passwords-table {
    font-size: 14px;
  }

  .passwords-table th,
  .passwords-table td {
    padding: 8px;
  }

  .buttons {
    flex-wrap: wrap;
  }
}
