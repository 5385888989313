// GlobalStyles.scss

$mainColor: #212121;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

body {
  background: $mainColor;
  color: white;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background-color: $mainColor;
}

.sidebar {
  background-color: #333;
  color: #fff;
  width: 200px;
  height: 100vh;
}

.menu {
  list-style: none;
  padding: 0;

  li {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;

    a {
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      display: block;

      &:hover {
        color: #ffcc00;
      }
    }
  }
}

.logout-button {
  margin-top: auto;
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c82333;
}

.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #212121 inset !important;
}

.task,
.note,
.reminder {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #212121;
  border-radius: 5px;

  h2,
  h3 {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
  }

  &.edit-mode {
    input,
    textarea {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }
}

$mainColor: #212121;
$cardBackground: #2c2c2c; // A slightly lighter shade for better contrast
$cardBorderColor: #444; // Darker border for a sleek look
$cardShadow: rgba(0, 0, 0, 0.2); // Softer shadow for a subtle effect
$hoverColor: #333; // Darker hover color for better emphasis

.card {
  border: 1px solid $cardBorderColor;
  border-radius: 8px;
  padding: 20px;
  background-color: $cardBackground;
  // margin-bottom: 20px; // Increased margin for better spacing
  cursor: pointer;
  box-shadow: 0 4px 8px $cardShadow; // Enhanced shadow for a more pronounced effect
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 450px;

  &:hover {
    transform: translateY(-5px); // Lift effect on hover
    box-shadow: 0 6px 12px $cardShadow; // More pronounced shadow on hover
    background-color: $hoverColor; // Background color change on hover
  }

  .item-header {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
  }
  .form-control {
    background-color: #2c2c2c; /* Dark background */
    color: #f1f1f1; /* Light text for contrast */
    border: 1px solid #444; /* Darker border */
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    transition: border-color 0.3s ease;

    /* Placeholder text color */
    &::placeholder {
      color: #888;
    }

    /* Focus and hover states */
    &:hover {
      border-color: #555;
    }

    &:focus {
      outline: none;
      border-color: #007bff; /* Accent color for focus */
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Light blue glow on focus */
    }
  }

  h3 {
    color: #f44336;
  }

  .item-view {
    width: 100%;
    height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
  }
  .edit-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .edit-textarea,
    .edit-input {
      width: 100%;
      min-height: 120px; // Increased height for better usability
      border: 1px solid $cardBorderColor;
      border-radius: 8px;
      padding: 10px;
      background-color: $cardBackground;
      color: white;
    }
    .reminder-content {
      input {
        background: $cardBackground;
      }
    }
    // .MuiButton-contained
  }
  .submit-button {
    background-color: $cardBackground;
    border: 1px solid white;
    border-radius: 5px; // Increased border radius for a more modern look
    padding: 8px 12px; // Adjusted padding for better alignment
    color: white;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .item-view {
    .handle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .reminder-content {
      input {
        background: $cardBackground;
      }
    }
    .note-content,
    .task-content,
    .reminder-content {
      word-wrap: break-word;
      display: flex;
      flex-direction: column;
      gap: 10px;
      resize: both;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .actions {
        display: flex;
        gap: 10px;
      }
    }
  }
  button,
  textarea {
    color: white;
  }
  input {
    color: white;
    border: 1px solid $cardBorderColor;
    border-radius: 8px;
    padding: 10px;
  }
}

@media (max-width: 1024px) {
  .card {
    width: 100%;
  }
}

.resizable-div {
  resize: both;
  overflow: auto;
}
.new-note-button {
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.task-content,
.note-content,
.reminder-content {
  word-wrap: break-word;
}
.item-content {
  white-space: pre-wrap;
}
.new-note-button:hover {
  background-color: #0056b3;
}

.super-button {
  .button {
    height: 50px;
    width: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    padding: 0.8em 1.8em;
    border: 1px solid #17c3b2;
    position: relative;
    background-color: transparent;
    text-align: center;
    z-index: 1;
    font-family: inherit;
    width: 100%;
    color: #17c3b2;
    transition: all ease-in-out 0.3s;
  }

  .button:hover {
    box-shadow: 0.5px 0.5px 150px #252525;
  }

  .type1::after {
    content: "Hi there want to see what i got ?";
    height: 50px;
    width: 100%;
    background-color: $mainColor;
    color: #17c3b2;
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translateY(50px);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
  }

  .type1::before {
    content: "Explore My Portfolio as a Guest";
    height: 50px;
    width: 100%;
    background-color: $mainColor;
    color: #17c3b2;
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translateY(0px) scale(1.2);
    font-size: 1rem;
    // font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
  }

  .type1:hover::after {
    transform: translateY(0) scale(1.2);
  }

  .type1:hover::before {
    transform: translateY(-50px) scale(0) rotate(120deg);
  }
}
.super-button-2 {
  .button {
    --color: #17c3b2;
    padding: 0.8em 1.7em;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-size: 1rem;
    border: 1px solid;
    font-family: inherit;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 20px;
    color: var(--color);
    color: white;
    z-index: 1;
  }

  .button::before,
  .button::after {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--color);
    transition: 1s ease;
  }

  .button::before {
    top: -2em;
    left: -2em;
  }

  .button::after {
    left: calc(100% + 2em);
    top: calc(100% + 2em);
  }

  .button:hover::before,
  .button:hover::after {
    height: 610px;
    width: 610px;
  }

  .button:hover {
    color: rgb(10, 25, 30);
  }

  .button:active {
    filter: brightness(0.8);
  }
}

.options {
  width: 100%;
  height: 150px;
  width: 100%;
  border-radius: 4px;
  background: #212121;
  display: flex;
  gap: 5px;
  padding: 0.4em;

  p {
    height: 100%;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    border-radius: 2px;
    transition: all 0.5s;
    background: #212121;
    border: 1px solid #ff5a91;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selected {
    flex: 8;
    span {
      transform: rotate(0);
    }
  }

  p span {
    min-width: 14em;
    padding: 0.5em;
    text-align: center;
    transform: rotate(-90deg);
    transition: all 0.5s;
    text-transform: uppercase;
    color: #ff568e;
    letter-spacing: 0.1em;
  }

  p:hover span {
    transform: rotate(0);
  }
}

.page-container {
  width: 100%;
}

.grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  grid-auto-rows: 300px;
  gap: 10px;
  padding: 10px;
}

/* For Mobile and Tablets */
@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(100%, 1fr)
    ); /* Adjusts for smaller screens */
    grid-auto-rows: max-content;
  }
}

/* For Mobile and Tablets */
@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(
      auto-fill,
      1fr
    ); /* Mobile/tablet width for columns */
    grid-auto-rows: max-content;
  }
}

.box {
  border: 1px solid #ccc;
  background-color: $mainColor;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
}
.github-repos-container {
  height: 100vh;
  margin: 0 auto;
  padding: 20px;
  overflow-y: scroll;
}

.repo-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.repo-card {
  width: calc(50% - 10px);
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: solid white 1px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  .buttons-container {
    display: flex;
    gap: 20px;
    color: white;
    .live-button {
      color: white;
      background-color: #007bff;
    }
    .source-button {
      background-color: rebeccapurple;
      color: white;
    }
  }
}

.repo-card h3 {
  margin-bottom: 10px;
}

.description {
  font-style: italic;
}
.message-container {
  display: flex;
  align-items: center;
}

.message-text {
  margin-right: 10px;
  font-size: 16px;
}

.copy-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.copy-text {
  position: absolute;
  right: -9999px;
}

.copy-button {
  background-color: #bbbbbb87; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}

.copy-button:hover {
  background-color: rebeccapurple; /* Darker green */
}
.list-container {
  display: flex;
  gap: 10px;
  padding: 10px;
  flex-wrap: wrap;
  height: calc(100vh - 150px);
  overflow-y: scroll;
}

/* Reminder Card Style */
.reminder-card {
  // background-color: #2c2c2c;
  border-radius: 8px;
  padding: 15px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #f1f1f1;
}

.reminder-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.reminder-card .countdown {
  font-size: 16px;
  font-weight: bold;
  color: #ff5a5a;
  margin: 10px 0;
}

.reminder-card p {
  font-size: 14px;
}

.reminder-card .actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.reminder-card .action-button {
  background: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.reminder-card .action-button:hover {
  color: #0056b3;
}
/* Notes Card Style */
.notes-card {
  // background-color: #2c2c2c;
  border-radius: 8px;
  padding: 15px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #f1f1f1;
}

.notes-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.notes-card p {
  font-size: 14px;
}

.notes-card .actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.notes-card .action-button {
  background: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.notes-card .action-button:hover {
  color: #0056b3;
}
/* Task Card Style */
.task-card {
  // background-color: #2c2c2c;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #f1f1f1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.task-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.task-card .status {
  font-size: 14px;
  font-weight: bold;
  color: #4caf50;
  margin-top: 10px;
}

.task-card .status.not-completed {
  color: #f44336;
}

.task-card .priority {
  font-size: 14px;
  font-weight: bold;
  color: #ff9800;
  margin: 10px 0;
}

.task-card .due-date {
  font-size: 14px;
  color: #9e9e9e;
}

.task-card p {
  font-size: 14px;
}

.task-card .actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.task-card .action-button {
  background: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.task-card .action-button:hover {
  color: #0056b3;
}
